.App {
    display: grid;
    font-family: 'Verdana', Courier, monospace;
}

.header_image_video {
    flex: 1;
    width: 100%;
    object-fit: cover;
    object-position: top;
    z-index: -1;
    height: 100%;
    position: absolute;
}

.cont {
    align-self: center;
    justify-self: center;
}

.desc {
    padding: 15px 20px;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    text-align: center;
}

.yel {
    color: #EDD900;
}

/* CSS */
.button-29 {
    align-self: center;
    justify-self: center;
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #F9E59A 0, #EDD900 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #000;
    cursor: pointer;
    display: inline-flex;
    height: 58px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 26px;
    padding-right: 26px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 22px;
    box-shadow: rgba(0, 0, 0, 1) 0px 50px 50px 0px;
}

/* .button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
} */

.button-29:hover {
    transform: translateY(-2px);
}

.button-29:active {
    transform: translateY(2px);
}
